import React, { useEffect, useState } from "react";
import { useGetProductsQuery } from "../../Redux/Action/ProductAction";
import { Loader } from "../../Utils/Loader";
import { DropDownMenu } from "../Common/DropDownMenu/DropDownMenu";
import { Order, Product, menuItem } from "../../services/Data";
import { DropDownMenuWithButton } from "../Common/DropDownMenuWithButton/DropDownMenuWithButton";
import { Link } from "react-router-dom";
import Filter from "../commoncomponents/Filter";
import { Dropdown } from "react-bootstrap";
import { SliderMenu } from "../Common/SliderMenu";
import { FilterItem } from "../Common/Filter/FilterItem";

import ReusableDataTable from "../../Shared/ReusableDataTable/ReusableDataTable";
import { Skeleton } from "primereact/skeleton";
export const Products = () => {
  useEffect(() => {
    document.title = "Products | Appjii";
  }, []);
  const actionTemplate = () => {
    return (
      <>
        <React.Fragment>
          <div className="edit_icon fz17">
            <i className="fa-light fa-edit me-3 fonticon"></i>
            <i className="fa-light fa-trash fonticon"></i>
          </div>
        </React.Fragment>
      </>
    );
  };
  const statusOrderBodyTemplate = () => {
    return <></>;
  };
  const searchBodyTemplate = () => {
    return <></>;
  };
  const [selectedProducts, setSelectedProducts] = useState(null);
  const columns = [
    { headerStyle: { width: "6rem" }, selectionMode: "multiple" },
    { field: "action", header: "Action", body: actionTemplate },
    { field: "id", header: "Id", sortable: true },
    { field: "title", header: "Title", sortable: true },
    { field: "brand", header: "Brand", sortable: true },
    { field: "price", header: "Amount", sortable: true },
    {
      field: "availabilityStatus",
      header: "Svailability Status",
      sortable: true,
    },
    { headerStyle: { width: "4rem" }, body: searchBodyTemplate },
  ];

  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [first, setFirst] = useState(0);
  const { data: product, isFetching } = useGetProductsQuery({ limit, skip });

  const total = product?.total;
  const onPageChange = (event) => {
    setFirst(event.first);
    setSkip(event.page * event.rows);
    setLimit(event.rows);
  };
  const [products, setProducts] = useState(false);

  const handleOpenDropDown = () => {
    setProducts(!products);
  };
  const [selectOrderValue, setSelectOrderValue] = useState("Departed");

  const handleOrderChange = (eventKey) => {
    setSelectOrderValue(eventKey);
  };
  const [buttonMenu, setButtonMenu] = useState(false);

  const handleToogleMenu = () => {
    setButtonMenu(!buttonMenu);
  };

  const [filterOpen, setFilterOpen] = useState(false);
  const handleNewViewClick = () => {
    setButtonMenu(false);
    setFilterOpen(!filterOpen);
  };
  const [dragItem, updateDragItem] = useState();
  const [list, updateList] = useState(["Exporter", "Buyer", "All"]);
  const handleDragStart = (index) => {
    updateDragItem(index);
    console.log(index);
  };
  const handleDragEnter = (e, index) => {
    const newList = [...list];
    const item = newList[dragItem];
    newList.splice(dragItem, 1);
    newList.splice(index, 0, item);
    updateDragItem(index);
    updateList(newList);
  };

  const [productSlider, setProductSlider] = useState(false);
  const openProductSlider = () => {
    setProductSlider(!productSlider);
  };
  
  return (
    <div className="body-content flex-grow-1 pt-xl-1">
      <div className="border-bottom p-3 px-xl-4">
        <div className="d-sm-flex align-items-center flex-wrap header_data">
          <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
            <h2 className="fz18 me-3 me-md-2 text-blue fw500 mb-0">Products</h2>
            <div className="dropdown">
              <DropDownMenu
                isOpen={products}
                toggleDropdown={handleOpenDropDown}
                options={Order}
                handleOrderChange={handleOrderChange}
                selectOrderValue={selectOrderValue}
              />
            </div>
            <ul className="progress-departed-arrived ex-by-al justify-content-sm-start me-md-1 d-none d-xl-flex flex-wrap gap-sm-2 mt-2 mt-sm-0 ms-2">
              {list.map((item, index) => (
                <li
                  draggable
                  key={index}
                  className={`item ${index === dragItem ? "dragging" : ""}`}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Link to="#" className=" btn border">
                    <span className="dragger ">
                      <i className="fa-solid fa-grip-dots-vertical"></i>
                    </span>{" "}
                    {item}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="dropdown-container">
              <DropDownMenuWithButton
                isOpen={buttonMenu}
                toggleDropdown={handleToogleMenu}
                options={menuItem}
                handleNewViewClick={handleNewViewClick}
              />
            </div>
          </div>
          <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
            <Dropdown className="me-2 list_menu">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="btn-gradient btn fz16 shadow-none border dropdown-toggle-none dropdown-toggle btn "
              >
                <i className="fa-light fa-table-list"></i> List
                <i className="fa-light fa-chevron-down ms-3"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-with-search py-0 pb-0 animates slideIns ">
                <Dropdown.Item>
                  <Link className="dropdown-item" to="/orders">
                    <i className="fa-light me-2 fa-table-list"></i>List
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="dropdown-item" to="orders/board">
                    <i className="fa-light me-2 fa-table-cells-large"></i> Board
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link
              to="#"
              className="btn-gradient btn  me-2"
              onClick={openProductSlider}
            >
              <i className="fa-regular fa-plus"></i> New
            </Link>

            <Filter />
            <div className="position-relative order-1 order-xl-2  main-search-box  order-md-2 mx-2">
              <input
                type="text"
                className="form-control rounded p-2"
                placeholder="Search"
                name="searchValue"
                // value={searchValue}
                // onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                <i className="fa-light fa-search"></i>
              </button>
            </div>

            <div className="order-4 custom-dessign-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-gradient btn fz16 shadow-none border dropdown-toggle-none"
                >
                  <i className="fa-light fa-ellipsis"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-with-search animates slideIns mt-1 p-0">
                  {Product?.map((item, i) => {
                    return (
                     
                        <Dropdown.Item key={i} className="py-2">
                          {item.name}
                        </Dropdown.Item>
                    
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper flex-grow-1  remove-first-sort remove-second-sort">
  
        <ReusableDataTable
          value={product?.products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          limit={limit}
          total={total}
          first={first}
          columns={columns}
          isFetching={isFetching}
          onPageChange={onPageChange}
        />
      </div>

      <SliderMenu
        show={filterOpen}
        setShow={setFilterOpen}
        title={
          <span className="fz20 title">
            <i className="fa-light fa-filter"></i> Filter *{" "}
            <span className="text-green">Reset</span>
          </span>
        }
        placement="end"
        sliderWidth="50%"
        body={
          <FilterItem
            setShow={setFilterOpen}
            setFilterOpen={setFilterOpen}
            filterOpen={filterOpen}
          />
        }
      />
      <SliderMenu
        show={productSlider}
        setShow={setProductSlider}
        placement="end"
        title="Add Product"
      />
    </div>
  );
};
