import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SliderMenu } from "../Common/SliderMenu";
import MobileMenu from "./MobileMenu";
import { useAuth0 } from "@auth0/auth0-react";
export default function Header() {
  const [selectedVendor, setSelectedVendor] = useState("Select Vendor");
  const handleSelect = (eventKey) => {
    setSelectedVendor(eventKey);
  };
  const [closeIcon, setCloseIcon] = useState(false);
  const [show, setShow] = useState(false);
  const sideBarOpen = () => {
    setShow(!show);
    setCloseIcon(true);
  };
  const handleMenuItemClick = () => {
    setShow(false);
  };
  const { user, logout } = useAuth0();

  return (
    <header className="top-header">
      <div className="d-flex justify-content-between gap-3 align-items-center">
        <div className="d-flex align-items-center me-auto">
          {/* mobile view icon */}
          <div className="fz22 d-xl-none me-3">
            <i className="fa-regular fa-bars-sort" onClick={sideBarOpen}></i>
          </div>
          <SliderMenu
            show={show}
            setShow={setShow}
            placement="start"
            sliderWidth="250px"
            closeIcon={closeIcon}
            setCloseIcon={setCloseIcon}
            body={<MobileMenu handleMenuItemClick={handleMenuItemClick} />}
          />

          {/* mobile view icon */}
          <div className="sm-logo me-2 d-xl-none">
            <Link to="https://wireframe.impexdocs.com/">
              {" "}
              <img src="assets/img/logo-icon.png" className="" alt="" />{" "}
            </Link>
          </div>
          <span className="fz16 fw700 d-none d-xl-block me-2">Exporter : </span>
          <div className="dropdown coles-supermarket">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="d-block p-0 border-0 outline-0 "
              >
                {selectedVendor}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-with-search vendor  pb-0 animate slideIn">
                <div className="form-group border-bottom pb-2 px-2">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control p-2"
                    // value={searchTerm}
                    // onChange={handleSearchChange}
                  />
                  <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                    <i className="fa-light fa-search"></i>
                  </button>
                </div>

                <Dropdown.Item
                  eventKey="Edukatex Vendor"
                  className="py-2  px-2"
                >
                  Edukatex Vendor
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey=" Kamakura Ham Murai Shokai Co Ltd"
                  className="py-2  px-2"
                >
                  Kamakura Ham Murai Shokai Co Ltd
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="Huadong Food Suzhou Merlog"
                  className="py-2  px-2"
                >
                  Huadong Food Suzhou Merlog
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="Murai Shokai Co Ltd"
                  className="py-2  px-2"
                >
                  Murai Shokai Co Ltd
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <ul className="header-right-links d-flex align-items-center">
            <li>
              <Link to="/supports">
                <i className="fa-light fa-circle-info"></i>
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <i className="fa-light fa-comment-dots"></i>
              </Link>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="w-100 d-block p-0 border-0 outline-0 dropdown-toggle-none"
                >
                  <div className="noti-list">
                    <i className="fa-light fa-bell"></i>
                    <span className="position-absolute top-0  translate-middle badge">
                      9
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="notifications animates slideIns">
                  <div className="text-small notification-header  dropdown-slide-right  dropdown-menu-end">
                    <div className="dropdown-header border-bottom d-flex align-items-center notification-name-head ">
                      <span
                        width="30"
                        height="30"
                        className="profile-icon rounded-circle me-3"
                      >
                        <i className="fa-light fa-bell"></i>{" "}
                      </span>
                      <h5 className="fz18 mb-0"> New Notification </h5>
                      <span className="btn btn-danger rounded btn-sm ms-auto">
                        15
                      </span>
                    </div>
                    <div className="dropdown-body">
                      <ul>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">JD</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                John Doe
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">S</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                Smith
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">M</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                Mark
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">AD</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                Ashwani Dhawan
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">JK</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                Jayant Kumar
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex">
                            <span className="notify-img me-3">JD</span>
                            <div>
                              <Link to="" className="fz14 text-dark">
                                John Doe
                              </Link>
                              <div className="desc fz14">
                                {" "}
                                Lorem ipsum dolor sit amet consectetur{" "}
                                <Link
                                  to=""
                                  className="text-black fz14 text-decoration-underline"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <date className="fz14 text-black-50">
                              02-02-2022
                            </date>{" "}
                            <div className="text-end fz14 text-black-50">
                              10 Min Ago
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-footer text-center py-3">
                      <Link className="text-black fz14" to="">
                        <i className="fa-light fa-eye me-1"></i> View All
                      </Link>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="d-none d-md-inline-block">
              <Link to="/">
                <i className="fa-light fa-gear"></i>
              </Link>
            </li>

            <li className="ms-4 ms-lg-5 ">
              <div className="flex-shrink-0 dropdown profile_img">
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="w-100 d-block p-0 border-0 outline-0"
                  >
                    <span
                      width="30"
                      height="30"
                      className="profile-icon rounded-circle me-2"
                    >
                      ak
                    </span>
                    {/* <img src={user?.picture} alt="profile" /> */}
                    <span className="d-none d-sm-inline">Ashwani Kumar</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-with-search dp_menu animate slideIn">
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="#">
                        <i className="fa-light menui_ico fa-gear"></i>Settings
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="#">
                        <i className="fa-light menui_ico fa-key"></i>Change
                        Password
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link className="dropdown-item" to="#">
                        <i className="fa-light menui_ico fa-user"></i>User
                        Profile
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() =>
                          logout({
                            logoutParams: { returnTo: window.location.origin },
                          })
                        }
                      >
                        <i className="fa-light menui_ico fa-right-from-bracket"></i>
                        Sign out
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
