// ReusableDataTable.js

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import React from "react";



const ReusableDataTable = ({
  value,
  selection,
  onSelectionChange,
  selectedProducts,
  onPageChange,
  limit,
  total,
  first,
  columns,
  rowsPerPageOptions = [20, 40, 60],
  emptyMessage = "No Product found.",
  isFetching
}) => {
  
  return (
    <DataTable
      
    //   value={value ?? Array.from({length:limit}).fill({
    //     "action": <Skeleton />,
    //     "id": <Skeleton/>,
    //     "title": <Skeleton/>,
    //     "brand": <Skeleton/>,
    //     "price": <Skeleton/>,
    //     "availabilityStatus": <Skeleton/>
    // })}
    value = {value ?? Array.from({length: limit}).fill(
      columns.reduce((acc, column) => {
        acc[column] = <Skeleton />;
        return acc;
      }, {})
    )}
      selection={selection} // Bind to selectedProducts state
      onSelectionChange={onSelectionChange} // Update state when selection changes
      selectionMode="multiple" // Enable multiple selection mode
      paginator={true}
      rows={limit}
      totalRecords={total}
      lazy={true}
      first={first}
      scrollable
      scrollHeight="75.8vh"
      tableStyle={{ minWidth: "50rem" }}
      onPage={onPageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate={`Showing ${first + 1} to ${Math.min(first + limit, total)} of ${total} entries`}
      emptyMessage={emptyMessage}
    >
      <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
      {columns.map((col, index) => (
        <Column
          key={index}
          field={col.field}
          header={col.header}
          body={isFetching ? <Skeleton/> : col.body}
          sortable={col.sortable}
        />
      ))}
    </DataTable>
  );
};

export default ReusableDataTable;
