import React, { useEffect, useState } from "react";
import "./Orders.css";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Filter from "../commoncomponents/Filter";
import { SliderMenu } from "../Common/SliderMenu";
import { AddOrders } from "../Orders/AddOrders";
import { useDispatch, useSelector } from "react-redux";
import { searchUser } from "../../Redux/Slice/SearchSlice";
import { DropDownMenu } from "../Common/DropDownMenu/DropDownMenu";
import { Order, Product, menuItem } from "../../services/Data";
import { DropDownMenuWithButton } from "../Common/DropDownMenuWithButton/DropDownMenuWithButton";
import { NotificationManager } from "react-notifications";
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
} from "../../Redux/Action/ordersAction";
import ConfirmationDialog from "../Common/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { Loader } from "../../Utils/Loader";
import { DataTables } from "../Common/DataTables/DataTables";
import { FilterItem } from "../Common/Filter/FilterItem";

export const Orders = () => {
  useEffect(() => {
    document.title = "Orders | Appjii";
  }, []);

  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(!show);
  };

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    dispatch(searchUser(searchValue));
  });

  const [buttonMenu, setButtonMenu] = useState(false);

  const handleToogleMenu = () => {
    setButtonMenu(!buttonMenu);
  };

  const [order, setOrder] = useState(false);

  const handleOpenDropDown = () => {
    setOrder(!order);
  };
  const [selectOrderValue, setSelectOrderValue] = useState("Departed");

  const handleOrderChange = (eventKey) => {
    setSelectOrderValue(eventKey);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(menuItem);
  const [filteredOrder, setFilteredOrder] = useState(Order);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredItems = menuItem.filter((item) =>
      item.label.toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(filteredItems);
  };
  const handleSearchOrder = (e) => {
    const searchOrder = e.target.value.toLowerCase();
    setSearchOrder(searchOrder);

    const filteredItems = Order.filter((item) =>
      item.name.toLowerCase().includes(searchOrder)
    );
    setFilteredOrder(filteredItems);
  };

  const [filterOpen, setFilterOpen] = useState(false);
  const handleNewViewClick = () => {
    setButtonMenu(false);
    setFilterOpen(!filterOpen);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [orderItem, setOrderItem] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);

  const editProduct = (rowData) => {
    setOrderItem(rowData);
    setOpen(!open);
  };
  const cancelItem = () => {
    setDeleteTarget(null);
  };
  const [deleteOrder] = useDeleteOrderMutation();

  const { data: orders, error, isLoading } = useGetOrdersQuery();

  useEffect(() => {
    if (error) {
      NotificationManager.error(error?.error);
    }
  }, [error]);

  const confirmDelete = (rowData) => {
    setDeleteTarget(rowData);
  };

  const handleDeleteConfirmation = async () => {
    setDeleteTarget(null);
    try {
      await deleteOrder(deleteTarget?.id);
      NotificationManager.success("Order delete successfully");
    } catch (error) {
      NotificationManager.error("Failed to delete order");
    }
  };
  const result = useSelector((state) => state?.orders);
  const { searchData, isSearch } = result;
  const filteredOrders = orders?.filter(
    (order) =>
      (order.customer ? order.customer.toLowerCase() : "").includes(
        searchData?.toLowerCase()
      ) ||
      (order.status ? order.status.toLowerCase() : "").includes(
        searchData?.toLowerCase()
      ) ||
      (typeof order.phone === "string"
        ? order.phone.toLowerCase()
        : ""
      ).includes(searchData?.toLowerCase()) ||
      (searchData === "pending" &&
        order.status &&
        order.status.toLowerCase() === "pending") ||
      (searchData === "confirmed" &&
        order.status &&
        order.status.toLowerCase() === "confirmed")
  );

  const actionTemplate = (rowData, column) => {
    return (
    
        <div className="edit_icon fz17" key={column}>
          <i
            className="fa-light fa-edit me-3 fonticon"
            onClick={() => editProduct(rowData)}
          ></i>
          <i
            className="fa-light fa-trash fonticon"
            onClick={() => confirmDelete(rowData)}
          ></i>
          
        </div>
     
    );
  };

  const statusTemplate = (rowData) => {
    const statusClassName =
      rowData.status === "Confirmed" ? "status_cinf" : "status_pen";
    return <span className={statusClassName}>{rowData.status}</span>;
  };

  const columns = [
    { headerStyle: { width: "6rem" }, selectionMode: "multiple" },
    { field: "action", header: "Action", body: actionTemplate },
    { field: "id", header: "Id", sortable: true },
    { field: "status", header: "Status", body: statusTemplate, sortable: true },
    { field: "orderdate", header: "Order Date", sortable: true },
    { field: "customer", header: "Customer", sortable: true },
    { field: "phone", header: "Phone", sortable: true },
    { field: "total", header: "Total", sortable: true },
    
  ];
  const [dragItem, updateDragItem] = useState();
  const [list, updateList] = useState(["Exporter", "Buyer", "All"]);
  const handleDragStart = (index) => {
    updateDragItem(index);
    console.log(index);
  };
  const handleDragEnter = (e, index) => {
    const newList = [...list];
    const item = newList[dragItem];
    newList.splice(dragItem, 1);
    newList.splice(index, 0, item);
    updateDragItem(index);
    updateList(newList);
  };
const handleGetValue=(value)=>{
// console.log("value",value);
}

const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
const handleDeleteMultipleOrder = () => {
  setShowDeleteConfirmation(true);
};
const handleDeleteMultipleOrders = async () => {
  setShowDeleteConfirmation(false);
  try {
    // Iterate through selected products and delete each order
    for (let i = 0; i < selectedProducts.length; i++) {
      const orderId = selectedProducts[i].id;
      await deleteOrder(orderId); // Delete each selected order
    }
    NotificationManager.success("Orders deleted successfully");
    setSelectedProducts([]); // Clear selection after deletion
  } catch (error) {
    NotificationManager.error("Failed to delete orders");
  }
};
  return (
    <div className="body-content flex-grow-1 pt-xl-1">
      <div className=" border-bottom p-3 px-xl-4">
        <div className="d-sm-flex align-items-center flex-wrap header_data">
          <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
            <h2 className="fz18 me-3 me-md-2 text-blue fw600 mb-0">Orders</h2>
            <div className="dropdown">
              <DropDownMenu
                isOpen={order}
                toggleDropdown={handleOpenDropDown}
                options={filteredOrder}
                searchOrder={searchOrder}
                handleSearchOrder={handleSearchOrder}
                handleOrderChange={handleOrderChange}
                selectOrderValue={selectOrderValue}
              />
            </div>
               {/* Bulk delete button */}
        <button
          className="btn-gradient btn border"
          onClick={handleDeleteMultipleOrder}
          disabled={selectedProducts.length === 0} // Disable if no orders selected
        >
          Delete Selected Orders
        </button>
            <ul className="list progress-departed-arrived ex-by-al justify-content-sm-start me-md-1 d-none d-xl-flex flex-wrap gap-sm-2 mt-2 mt-sm-0 ms-2">
              {list.map((item, index) => (
                <li
                  draggable
                  key={index}
                  className={`item ${index === dragItem ? "dragging" : ""}`}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={(e) => handleDragEnter(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Link to="#" className=" btn border" onClick={()=>handleGetValue(item)}>
                    <span className="dragger ">
                      <i className="fa-solid fa-grip-dots-vertical"></i>
                    </span>
                    {item}
                  </Link>
                </li>
              ))}
            </ul>

            <div className="dropdown-container">
              <DropDownMenuWithButton
                isOpen={buttonMenu}
                toggleDropdown={handleToogleMenu}
                options={filteredOptions}
                handleNewViewClick={handleNewViewClick}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="mt-3 mt-sm-0 align-items-center ms-sm-4 ms-md-auto mt-xl-0 d-flex justify-content-end flex-grow-1">
            <Dropdown className="me-2 list_menu" >
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="btn-gradient btn fz16 shadow-none border dropdown-toggle-none dropdown-toggle btn "
              >
                <i className="fa-light fa-table-list"></i> List
                <i className="fa-light fa-chevron-down ms-3"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-with-search pt-0 pb-0 animates slideIns">
                <Dropdown.Item>
                  <Link className="dropdown-item" to="/orders">
                    <i className="fa-light me-2 fa-table-list"></i>List
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="dropdown-item" to="/orders/board">
                    <i className="fa-light me-2 fa-table-cells-large"></i> Board
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Link
              to="#"
              className="btn-gradient btn  me-2"
              onClick={handleOpen}
            >
              <i className="fa-regular fa-plus"></i> New
            </Link>

            <Filter />
            <div className="position-relative order-1 order-xl-2  main-search-box  order-md-2 mx-2">
              <input
                type="text"
                className="form-control  rounded p-2"
                placeholder="Search"
                name="searchValue"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
                <i className="fa-light fa-search"></i>
              </button>
            </div>

            <div className="order-4 custom-dessign-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-gradient btn fz16 shadow-none border dropdown-toggle-none"
                >
                  <i className="fa-light fa-ellipsis"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-with-search animates slideIns mt-1 p-0">
                  {Product?.map((item, i) => {
                    return (
                      <React.Fragment>
                        <Dropdown.Item key={i} className="py-2">{item.name}</Dropdown.Item>
                      </React.Fragment>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper flex-grow-1 px-2 remove-first-sort remove-second-sort">
        {isLoading ? (
          <Loader />
        ) : (
          <DataTables
            selectionMode="checkbox"
            resizableColumns
            responsiveLayout="scroll"
            selection={selectedProducts}
            onSelectionChange={(e) => {
              setSelectedProducts(e.value);
            }}
            scrollable scrollHeight="75.8vh" tableStyle={{ minWidth: '50rem' }}
            value={isSearch ? filteredOrders : orders}
            columns={columns}
            rows={20}
            paginator
            rowsPerPageOptions={[20, 25, 50]}
            dataKey="id"
            // stripedRows
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          />
        )}
      </div>
      <SliderMenu
        show={show}
        setShow={setShow}
        title="Add Orders"
        placement="end"
        sliderWidth="500px"
        body={<AddOrders setShow={setShow} />}
      />
      <SliderMenu
        show={open}
        title="Update Orders"
        setShow={setOpen}
        placement="end"
        sliderWidth="500px"
        body={
          <AddOrders
            orderItem={orderItem}
            setShow={setOpen}
            setOpen={setOpen}
            open={open}
          />
        }
      />
      <SliderMenu
        show={filterOpen}
        setShow={setFilterOpen}
        title={
          <span className="fz20 title">
            <i className="fa-light fa-filter"></i> Filter *{" "}
            <span className="text-green">Reset</span>
          </span>
        }
        placement="end"
        sliderWidth="50%"
        body={
          <FilterItem
            setShow={setFilterOpen}
            setFilterOpen={setFilterOpen}
            filterOpen={filterOpen}
          />
        }
      />

      <ConfirmationDialog
        title="Confirm Delete"
        message="Are you sure you want to delete this order?"
        onConfirm={handleDeleteConfirmation}
        onCancel={cancelItem}
        show={!!deleteTarget}
      />
           <ConfirmationDialog
        title="Confirm  Delete"
        message="Are you sure you want to delete the selected orders?"
        onConfirm={handleDeleteMultipleOrders}
        onCancel={() => setShowDeleteConfirmation(false)} // Close dialog on cancel
        show={showDeleteConfirmation}
      />
    </div>
  );
};
