import { Navigate } from "react-router-dom";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard/Dashboard";

import { HomeLayOut } from "./components/LayOut/HomeLayOut";
import { Orders } from "./components/Orders/Orders";
import { Products } from "./components/Products/Products";
import MessageCenter from "./components/MessageCenter/MessageCenter";
import ProductBundles from "./components/ProductBundles/ProductBundles";
import Settings from "./components/Settings/Settings";
import Accounting from "./components/Accounting/Accounting";
import { Shipment } from "./components/Shilpment/Shipment";
import { Containers } from "./components/Containers/Containers";
import { Documents } from "./components/Documents/Documents";
import { Offers } from "./components/Offers/Offers";
import { Couriers } from "./components/Couriers/Couriers";
import { Report } from "./components/Report/Report";
import { Contact } from "./components/Contact/Contact";
import { Partners } from "./components/Partners/Partners";
import { Supports } from "./components/Supports/Supports";
import { Chat } from "./components/Chat/Chat";
import { Board } from "./components/Orders/Board/Board";


// Define public routes
export const publicRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <HomeLayOut>
        <Dashboard />
      </HomeLayOut>
    ),
  },
  {
    path: "/orders",
    element: (
      <HomeLayOut>
        <Orders />
      </HomeLayOut>
    ),
  },
  {
    path: "/products",
    element: (
      <HomeLayOut>
        <Products />
      </HomeLayOut>
    ),
  },
  {
    path: "/messagecenter",
    element: (
      <HomeLayOut>
        <MessageCenter />
      </HomeLayOut>
    ),
  },
  {
    path: "/productbundles",
    element: (
      <HomeLayOut>
        <ProductBundles />
      </HomeLayOut>
    ),
  },
  {
    path: "/accounting",
    element: (
      <HomeLayOut>
        <Accounting />
      </HomeLayOut>
    ),
  },
  {
    path: "/settings",
    element: (
      <HomeLayOut>
        <Settings />
      </HomeLayOut>
    ),
  },
  {
    path: "/shipment",
    element: (
      <HomeLayOut>
        <Shipment />
      </HomeLayOut>
    ),
  },
  {
    path: "/containers",
    element: (
      <HomeLayOut>
        <Containers />
      </HomeLayOut>
    ),
  },
  {
    path:"/documents",
    element:(
      <HomeLayOut>
        <Documents/>
      </HomeLayOut>
    )
  },
  {
    path:"/offers",
    element:(
      <HomeLayOut>
        <Offers/>
      </HomeLayOut>
    )
  },
  {
    path:"/couriers",
    element:(
      <HomeLayOut>
        <Couriers/>
      </HomeLayOut>
    )
  },
  {
    path:"/report",
    element:(
      <HomeLayOut>
        <Report/>
      </HomeLayOut>
    )
  },
  {
    path:"/partners",
    element:(
      <HomeLayOut>
        <Partners/>
      </HomeLayOut>
    )
  },
  {
    path:"/contacts",
    element:(
      <HomeLayOut>
        <Contact/>
      </HomeLayOut>
    )
  },
  {
    path:"/supports",
    element:(
      <HomeLayOut>
        <Supports/>
      </HomeLayOut>
    )
  },
  {
    path:"/chat",
    element:(
      <HomeLayOut>
        <Chat/>
      </HomeLayOut>
    )
  },
  {
    path:"orders/board",
    element:(
      <HomeLayOut>
        <Board/>
      </HomeLayOut>
    )
  }
];
