import React from "react";
import "./FilterItem.css";
export const FilterItem = ({filterOpen,setFilterOpen}) => {
  const cancelSlider=()=>{
    setFilterOpen(!cancelSlider)
  }
  return (
    <>
      <div className="filterBox">
        <div className="filter-form-wrap ">
          <div className="row">
            <div className="col-6 gx-3 row">
              <div className="col flex-grow-0 position-relative">
                <span className="btn fz16 fw500 cursor-normal">1</span>
                <span className="and-or-text fz12 text-blue fw700">AND</span>
              </div>
              <div className="col">
                <div className="form-group">
                  <select className="form-control px-3">
                    <option value="">Arrive Date</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <select className="form-control px-3">
                    <option value="">No. of Days</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6 gx-3 row">
              <div className="col">
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Before"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="After"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-4 flex-grow-0 gap-2 d-flex">
                <button className="btn border text-red border-red bg-transparent ">
                  <i className="fa-solid fa-minus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6 gx-3 row">
              <div className="col flex-grow-0">
                <span className="btn fz16 fw500 cursor-normal">2</span>
              </div>
              <div className="col">
                <div className="form-group">
                  <select className="form-control px-3">
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <select className="form-control px-3">
                    <option value="">Select</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6 gx-3 row">
              <div className="col">
                <div className="form-group">
                  <input type="text" disabled className="form-control" />
                </div>
              </div>
              <div className="col-4 flex-grow-0 gap-2 d-flex">
                <button className="btn border text-red border-red">
                  <i className="fa-solid fa-minus"></i>
                </button>
                <button className="btn border text-green border-green">
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h4 className="fz14 mb-0 fw700">Sort By</h4>
          </div>
          <div className="row mt-2">
            <div className="col-6 col-sm-4">
              <div className="form-group">
                <select className="form-control px-3">
                  <option value="">Arrive Date</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <h4 className="fz14 mb-0 ">Customize Column</h4>
            </div>
            <div className="col-12 mt-2">
              <div className="row">
                <div className="col-sm-6">
                  <div className="dropdown-with-search before-none">
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        className="form-control  px-3  rounded p-2"
                        placeholder="Search Fields"
                      />
                    </div>
                    <h4 className="fz14">Choose Column</h4>
                    <ul>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox1"
                          >
                            Shipment No.
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox2"
                          >
                            Buyer Name
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox3"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox3"
                          >
                            Destination
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox4"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox4"
                          >
                            Discharge Port
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox5"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox5"
                          >
                            Departure
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox6"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox6"
                          >
                            Cargo Cutoff
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox7"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox7"
                          >
                            Satus
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check ps-0 mb-2 form-check-inline">
                          <input
                            className="ms-1 form-check-input"
                            type="checkbox"
                            id="inlineCheckbox8"
                          />
                          <label
                            className="fz14 ms-4  form-check-label"
                            for="inlineCheckbox8"
                          >
                            Created
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="selected-column h-100 p-3">
                    <ul>
                      <li>
                        <div className="row gx-2 align-items-center mb-2">
                          <div className="col  fz14">Selected Column (6)</div>
                          <div className="col-3 fz14  flex-grow-0">Size</div>
                        </div>
                      </li>
                      <li className="row gx-2 align-items-center mb-2">
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border">
                            <i className="fa-regular fa-lock fz16 me-2"></i>{" "}
                            Shipment No.
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                      <li className="row gx-2 align-items-center mb-2">
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border">
                            <i className="fa-regular fa-lock fz16 me-2"></i>{" "}
                            Shipment No.
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                      <li className="row gx-2 align-items-center mb-2">
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border">
                            <i className="fa-regular fa-lock fz16 me-2"></i>{" "}
                            Shipment No.
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                    </ul>
                    <ul data-projection-id="13">
                      <li
                        className="row gx-2 align-items-center mb-2"
                        draggable="false"
                        data-projection-id="14"
                        style={{
                          zIndex: "unset",
                          transform: "none",
                          userSelect: "none",
                          touchAction: "pan-x",
                        }}
                      >
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border position-relative d-flex align-items-center">
                            <i className="fa-solid fa-grip-dots fz16 me-2"></i>{" "}
                            Discharge Port Departure
                            <button className=" py-0 btn ms-auto">
                              <i className="fa-light fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                      <li
                        className="row gx-2 align-items-center mb-2"
                        draggable="false"
                        data-projection-id="15"
                        style={{
                          zIndex: "unset",
                          transform: "none",
                          userSelect: "none",
                          touchAction: "pan-x",
                        }}
                      >
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border position-relative d-flex align-items-center">
                            <i className="fa-solid fa-grip-dots fz16 me-2"></i>{" "}
                            Discharge Port Cargo Cutoff
                            <button className=" py-0 btn ms-auto">
                              <i className="fa-light fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                      <li
                        className="row gx-2 align-items-center mb-2"
                        draggable="false"
                        data-projection-id="16"
                        style={{
                          zIndex: "unset",
                          transform: "none",
                          userSelect: "none",
                          touchAction: "pan-x",
                        }}
                      >
                        <div className="col">
                          <div className="bg-white fz14  h-100 px-2 py-2 rounded border position-relative d-flex align-items-center">
                            <i className="fa-solid fa-grip-dots fz16 me-2"></i>{" "}
                            Discharge Port Discharge Port
                            <button className=" py-0 btn ms-auto">
                              <i className="fa-light fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div className="col-3 flex-grow-0">
                          <input
                            type="text"
                            className="form-control"
                            value="100px"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter_button offcanvsfooter ">
            <div className="btn-right  px-5">
              <button className="btn btn-gradient fz14 btn-light text-black ms-2" onClick={cancelSlider}>
                Cancel
              </button>
              <button className="btn btn-primary fz14 btn-blue text-white ms-2">
                Search
              </button>
              <button className="btn btn-primary fz14 btn-blue text-white ms-2">
                Save & Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
