import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-5 nofoundpage">
          <div className="row">
            <div className="col-md-12 error-container">
              <h2 className="gb_error">Something went wrong!</h2>

              {/* Display error details only in development */}
              {process.env.NODE_ENV === "development" && (
                <>
                  <p className="page_error">{this.state.error?.message}</p>
                  <p className="page_error">{this.state.error?.name}</p>
                  <p className="page_error">{this.state.error?.stack}</p>
                </>
              )}

              {/* Hide the error details and show the retry button */}
              <button
                onClick={this.handleRetry}
                className="btn btn-home common_btn"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
