import React from "react";

import { useSelector } from "react-redux";
import Sidebar from "../includes/Sidebar";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import ErrorBoundary from "../../GlobalErrorBoundary";

export const HomeLayOut = ({ children }) => {
  const SidebarToggle = useSelector((state) => state.toogle.show);

  return (
    <>
      <div
        className={`template-default ${SidebarToggle ? "menu-closed" : ""}`}
        id="template-default-page"
      >
        <Sidebar />

        <div className="header-body-content">
          <Header />
          <main>
            <ErrorBoundary>
            {children}
            </ErrorBoundary>
          
            
            </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
